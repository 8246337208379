class Registration {
    constructor() {
        this.init();
    }

    init() {
        var inst = this;
        this.form = $("#registerForm");
        this.loadingBox = $('.loading-box');

        if (this.form.length > 0) {
            const $validator = this.form.validate();
            const headerClass = new Header;
            this.emailInputField = this.form.find("#RegisterAccountViewModel_Email");
            this.checkboxErrorMessage = $(".field-validation-error.top-products");
            this.currentStep = 0;
            this.navs = $(".registration__steps-navs .nav-link");
            this.newsletterCheckbox = $('input[name = "RegisterAccountViewModel.Newsletter"]');
            this.submitButton = $('#submitRegistrationButton');

            document.addEventListener('next step', function (e) {
                inst.nextStep();
            })

            this.navs.on('shown.bs.tab', function (e) {
                inst.currentStep = inst.navs.index(e.target);
            });

            this.newsletterCheckbox.on('click', function () {
                let status = this.checked;
                inst.submitButton.prop('disabled', !status)
            });

            this.form.validate();

            $('#RegisterAccountViewModel_Password2').rules("add", {
                equalTo: "#RegisterAccountViewModel_Password",
            });

            $('.jsUsersRegisterNextStep').each(function (i, e) {
                $(e).on('click', function (event) {
                    event.preventDefault();
                    //In Step 1, the email that has been entered can't be one that is already registered.
                    if (inst.currentStep === 0) {
                        if (inst.form.valid()) {
                            headerClass.checkEmail(inst.emailInputField);
                        }
                    }
                    //On step 2, at least one of the Top Products checkboxes needs to be checked, but no more than three
                    else if (inst.currentStep === 1) {
                        if (!inst.validateCheckboxes(inst.form)) {
                            inst.checkboxErrorMessage.show();
                            $validator.form();
                        } else {
                            inst.checkboxErrorMessage.hide();
                            if (inst.form.valid()) {
                                inst.nextStep();
                            } else {
                                $validator.form();
                            }
                        }
                    } else {
                        if (inst.form.valid()) {
                            inst.nextStep();
                        }
                    }
                });
            });

            $('.jsUsersRegisterPrevStep').each(function (i, e) {
                $(e).click(function (event) {
                    event.preventDefault();

                    inst.prevStep();
                });
            });

            $('.jsResetPasswordConfirm').each(function () {
                setTimeout(function () {
                    document.location = '/';
                }, 4000);
            });

            this.form.find('.registration__billing-address-check .custom-control-input').on('change', (ev) => {
                if(!ev.target.checked) {
                    this.form.find('.registration__billing-address').addClass('hidden');
                } else {
                    this.form.find('.registration__billing-address').removeClass('hidden');
                }
            });
            
            this.form.find('.registration__single-purchase--yes .custom-control-input').on('change', () => {
                this.form.find('.registration__related-invoice').removeClass('hidden');
            });
            
            this.form.find('.registration__single-purchase--no .custom-control-input').on('change', () => {
                this.form.find('.registration__related-invoice').addClass('hidden');
            });
        }
    }

    validateCheckboxes(container) {
        let numChecks = $(container).find('input[name="RegisterAccountViewModel_TopProducts"]:checked').length;
        return (numChecks > 0 && numChecks < 4);
    }

    nextStep() {
        this.currentStep++;
        this.setTabEnabled(this.currentStep);
        this.navs.eq(this.currentStep).tab("show");
        scrollToElem(".registration-page");
    }

    prevStep() {
        this.currentStep--;
        this.navs.eq(this.currentStep).tab("show");
        scrollToElem(".registration-page");
    }

    setTabEnabled(index) {
        this.navs.eq(index).removeClass('disabled');
    }

    showLoading() {
        this.loadingBox.show();
    }

    hideLoading() {
        this.loadingBox.hide();
    }

}

